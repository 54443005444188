<template>
  <div class="main">
    <div class="row mx-4 my-4">
      <EmployeeCard></EmployeeCard>
      <div class="col-xl-9 col-lg-8 ps-4 userSettings">
        <ProfileProgress></ProfileProgress>
        <div class="mt-4 settingsBody rounded">
          <div class="pt-3 pb-0 ps-4">
            <strong>Procedencia</strong>
          </div>
          <hr />
          <div>
            <form class="settingForm" @submit.prevent="sendBasic">
              <div class="mb-3">
                <label for="country" class="form-label">País de Origen</label>
                <select
                  class="form-select text-capitalize"
                  name="country"
                  v-model="country"
                  @click="getCountries"
                  required
                >
                  <optgroup label="País Actual">
                    <option v-bind:value="currentCountry.ISOCode">{{
                      currentCountry.name
                    }}</option>
                  </optgroup>
                  <optgroup label="Otros Paises">
                    <option v-if="countryList.length === 0" disabled
                      >Cargando...</option
                    >
                    <option
                      v-for="country in countryList"
                      v-bind:value="country.ISOCode"
                      :key="country.ISOCode"
                      >{{ country.name }}</option
                    >
                  </optgroup>
                </select>
              </div>
              <div class="mb-3">
                <label for="state" class="form-label"
                  >Departamento de Origen</label
                >
                <select
                  class="form-select text-capitalize"
                  name="state"
                  v-model="state"
                  required
                >
                  <option
                    v-for="state in stateList"
                    v-bind:value="state._id"
                    :key="state._id"
                    >{{ state.name }}</option
                  >
                </select>
              </div>
              <div class="mb-3">
                <label for="city" class="form-label">Ciudad de Origen</label>
                <select
                  class="form-select text-capitalize"
                  name="city"
                  v-model="city"
                  required
                >
                  <option
                    v-for="city in cityList"
                    v-bind:value="city._id"
                    :key="city._id"
                    >{{ city.name }}</option
                  >
                </select>
              </div>
              <button type="submit mb-3" class="btn btn-primary shadow-primary">
                Actualizar
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <Legal></Legal>
  </div>
</template>

<script>
import Legal from "@/components/layout/Legal";
import EmployeeCard from "@/views/employee/EmployeeCard";
import ProfileProgress from "@/views/employee/ProfileProgress";

import Storage from "@/modules/storage";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "Employee Profile Edit Origin",
  components: { Legal, EmployeeCard, ProfileProgress },
  data() {
    return {
      country: "",
      currentCountry: {},
      countryList: [],
      state: "",
      stateList: [],
      city: "",
      cityList: []
    };
  },
  beforeCreate() {
    const store = new Storage();

    store.getData("employee", this.$route.params._id).then(employeeData => {
      store.getData("branch", employeeData.branch).then(branchData => {
        store.getData("city", branchData.city).then(cityData => {
          localStorage.setItem(
            "currentLocation",
            "Empleados / Perfiles / " +
              cityData.name +
              " / " +
              employeeData.name +
              " / Procedencia"
          );
        });
      });
    });
  },
  mounted() {
    const store = new Storage();
    store.getData("country").then(countryData => {
      this.currentCountry = countryData[0];
      store.getData("employee", this.$route.params._id).then(employee => {
        this.country = employee.countryOfBirth;
        this.state = employee.stateOfBirth;
        this.city = employee.cityOfBirth;
        if (this.country !== this.currentCountry.ISOCode) {
          const URL =
            new Storage().baseURL +
            "/public/country?where=ISOCode=" +
            this.country;
          axios
            .get(URL, {
              withCredentials: true
            })
            .then(response => {
              response.data.body.sort(function(a, b) {
                return a.name.localeCompare(b.name);
              });
              this.countryList = response.data.body;
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    });
  },
  methods: {
    getCountries() {
      const URL = new Storage().baseURL + "/public/country";
      axios
        .get(URL, {
          withCredentials: true
        })
        .then(response => {
          response.data.body.sort(function(a, b) {
            return a.name.localeCompare(b.name);
          });
          this.countryList = response.data.body;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    sendBasic() {
      Swal.fire({
        icon: "warning",
        title: "Actualizando información",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        stopKeydownPropagation: true,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          setInterval(() => {
            const content = Swal.getContent();
            if (content) {
              const b = content.querySelector("b");
              if (b) {
                b.textContent = Swal.getTimerLeft();
              }
            }
          }, 100);
        }
      });
      const store = new Storage();
      const data = {
        _id: this.$route.params._id,
        countryOfBirth: this.country,
        stateOfBirth: this.state,
        cityOfBirth: this.city
      };
      store
        .updateData("employee", [data], true, "optima")
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Datos Actualizados",
            text: "Todos los datos han sido sincronizados"
          }).then(() => {
            this.$router.go();
          });
        })
        .catch(error => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Error al actualizar los datos",
            text:
              "Los datos han sido guardados en su computador o celular, una vez se restablesca la conexión, los datos se sincronizarán con el servidor"
          }).then(result => {
            this.$router.go();
          });
        });
    }
  },
  watch: {
    country: function(data) {
      this.stateList = [];
      this.cityList = [];
      if (data === "") {
        return 0;
      } else if (data === this.currentCountry.ISOCode) {
        const store = new Storage();
        store.getData("state").then(stateData => {
          stateData.sort(function(a, b) {
            return a.name.localeCompare(b.name);
          });
          this.stateList = stateData;
        });
      } else {
        const URL =
          new Storage().baseURL + "/public/state?where=countryCode=" + data;
        axios
          .get(URL, {
            withCredentials: true
          })
          .then(response => {
            response.data.body.sort(function(a, b) {
              return a.name.localeCompare(b.name);
            });
            this.stateList = response.data.body;
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    },
    state: function(data) {
      this.cityList = [];
      if (data === "") {
        return 0;
      } else {
        const store = new Storage();
        store.getData("state", data).then(stateData => {
          if (stateData) {
            store.getData("city").then(cityData => {
              cityData.sort(function(a, b) {
                return a.name.localeCompare(b.name);
              });
              cityData.forEach(city => {
                if (city.state === data) {
                  this.cityList.push(city);
                }
              });
            });
          } else {
            const URL =
              new Storage().baseURL + "/public/city?where=state=" + data;
            axios
              .get(URL, {
                withCredentials: true
              })
              .then(response => {
                response.data.body.sort(function(a, b) {
                  return a.name.localeCompare(b.name);
                });
                this.cityList = response.data.body;
              })
              .catch(function(error) {
                console.log(error);
              });
          }
        });
      }
    }
  }
};
</script>

<style lang="css" scoped>
.settingsBody {
  background-color: var(--secondary-bg);
}
.settingForm {
  margin: auto;
  padding-bottom: 20px;
  width: 50%;
  min-width: 280px;
}
@media only screen and (max-width: 990px) {
  .userSettings {
    padding: 0 !important;
  }
  .userSettings {
    margin-block: 30px;
  }
}
</style>
